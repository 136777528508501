import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";

export default function PageHeader({ title, content, backUrl = "/" }) {
  return (
    <div className="pageheader-container d-flex bg-gradient-blueberry-lavender">
      <section className="container-fluid d-flex">
        <div className="container color-white d-flex flex-column justify-content-center">
          <div className="row d-flex flex-wrap-reverse flex-lg-wrap flex-lg-row align-items-center">

            <div className="col-12 col-lg-2 text-center">
              <Link to={backUrl} className="go-back py-2 mb-3 d-flex justify-content-center align-items-center">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path fill="currentColor" d="M231.536 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L60.113 273H436c6.627 0 12-5.373 12-12v-10c0-6.627-5.373-12-12-12H60.113L238.607 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L3.515 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z" />
                </svg>
                <span>Go back</span>
              </Link>
            </div>

            <div className="col-12 col-lg-8 my-4 main-leyend text-center">
              <h1 className={classNames({ "mb-4": !!content, "mb-0": !content })}>{title}</h1>
              <p>{content}</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
