import React, { Component } from "react";

// function copyValidityObject(validity) {
//   return [
//     "badInput", "customError", "patternMismatch",
//     "rangeOverflow", "rangeUnderflow", "stepMismatch",
//     "tooLong", "tooShort", "typeMismatch", "valueMissing", "valid"
//   ].reduce((accum, key) => ({ ...accum, [key]: validity[key] }), {});
// }

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

export default function withFormValidation(WrappedComponent) {
  /**
   * See for improvements https://css-tricks.com/form-validation-part-3-validity-state-api-polyfill/
   * Possible validation errors
   *
   * badInput: boolean
   * customError: boolean
   * patternMismatch: boolean
   * rangeOverflow: boolean
   * rangeUnderflow: boolean
   * stepMismatch: boolean
   * tooLong: boolean
   * tooShort: boolean
   * typeMismatch: boolean
   * valueMissing: boolean
   */
  class FormValidation extends Component {
    constructor(props) {
      super(props);

      this.state = {
        validationErrors: {},
      };

      this.validationElements = {};

      this.validate = this.validate.bind(this);
      this.initElement = this.initElement.bind(this);
      this.isValidElement = this.isValidElement.bind(this);
      this.focusElement = this.focusElement.bind(this);
      this.getFirstInvalidElement = this.getFirstInvalidElement.bind(this);
      this.focusFirstInvalidElement = this.focusFirstInvalidElement.bind(this);

      this.validation = {
        validate: this.validate,
        initElement: this.initElement,
        isValidElement: this.isValidElement,
        focusElement: this.focusElement,
        focusInvalidElement: this.focusFirstInvalidElement,
        getInvalidElement: this.getFirstInvalidElement
      };
    }

    getFirstInvalidElement(onlyName = false) {
      const some = Object.keys(this.validationElements)
        .find(key => !this.elementsValidity[key].valid);

      if (!onlyName && some) {
        return this.validationElements[some];
      }

      return some;
    }

    focusElement(elementName) {
      const element = this.validationElements[elementName];

      if (element) {
        element.focus();
      }
    }

    isValidElement(elementName, errorType = null) {
      const { validationErrors } = this.state;
      const element = this.validationElements[elementName];
      const validation = validationErrors[elementName];

      if (!validation || !element) {
        return true;
      }

      if (element.type === "checkbox" && element.required) {
        return element.checked;
      }

      // if (element.attributes.matchto) {
      //   const matchto = this.validationElements[element.attributes.matchto.value];
      //   element.setCustomValidity(element.value !== matchto.value ? "no-match" : "");
      //   element.checkValidity();
      // }

      if (!errorType) {
        return validation.valid;
      }

      return !validation[errorType];
    }


    validate() {
      const validationErrors = {};

      Object
        .keys(this.validationElements)
        .forEach(key => {
          const element = this.validationElements[key];
          this.checkMatchValidation(element);

          let { validity } = element;

          if (element.type === "checkbox" && element.required) {
            validity = JSON.parse(JSON.stringify(validity));
            validity.valid = element.checked;
            validity.valueMissing = !element.checked;
          }

          if (!validity.valid) {
            validationErrors[key] = validity;
          }
        });

      this.setState({
        validationErrors
      });

      return Object.keys(validationErrors).length === 0;
    }

    focusFirstInvalidElement() {
      const some = Object.keys(this.validationElements)
        .find(key => !this.validationElements[key].validity.valid);

      if (some) {
        this.validationElements[some].focus();
      }
    }

    initElement(elementRef, name = null) {
      if (elementRef && !this.validationElements[name]) {
        this.validationElements[name || elementRef.name] = elementRef;

        if (elementRef.attributes.matchto) {
          elementRef.addEventListener("input", () => this.checkMatchValidation(elementRef));
        }
      }
    }

    checkMatchValidation(element) {
      if (element.attributes.matchto) {
        const matchto = this.validationElements[element.attributes.matchto.value];
        element.setCustomValidity(element.value !== matchto.value ? "no-match" : "");
        element.checkValidity();
      }
    }

    render() {
      return <WrappedComponent validation={this.validation} {...this.props} />;
    }
  }

  FormValidation.displayName = getDisplayName(WrappedComponent);

  return FormValidation;
}
