/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from "react";
import { Link } from "gatsby";
import ReCAPTCHA from "react-google-recaptcha";
import classNames from "classnames";
import withFormValidation from "../../utils/withFormValidation";

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      help: '',
      name: '',
      email: '',
      phone: '',
      company: '',
      key: '',
      busy: false,
      error: false,
      success: false,
    };

    this.clearError = this.clearError.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCaptchaCompleted = this.handleCaptchaCompleted.bind(this);

    this.validation = props.validation;
  }

  clearError(event) {
    event.preventDefault();

    this.setState({
      error: false
    });
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
      error: false,
    });
  }

  handleCaptchaCompleted(key) {
    this.setState({
      key,
      error: false,
    }, () => {
      if (this.wasSubmitted) {
        this.validation.validate();
      }
    });
  }

  handleOnSubmit(event) {
    event.preventDefault();

    const { busy } = this.state;
    if (busy) {
      return;
    }

    this.wasSubmitted = true;

    if (this.validation.validate()) {
      const { help, name, email, phone, company, key } = this.state;

      this.setState({
        busy: true,
        error: false,
        success: false,
      }, () => {
        fetch('/.netlify/functions/contact', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ type: 'contact', name, company, email, phone, help, key })
        }).then(() => {
            this.setState({
              busy: false,
              success: true,
            });
          })
          .catch(() => {
            this.setState({
              busy: false,
              error: true,
            });
          })
      });
    } else {
      this.validation.focusInvalidElement();
    }
  }

  render() {
    const { help, name, email, phone, company, key, busy, success, error } = this.state;

    return (
      <section className="container-fluid bg-gray">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 col-sm-10 offset-sm-1 col-xl-8 offset-xl-2">
              <form noValidate onSubmit={this.handleOnSubmit} className="row my-5" hidden={success}>
                <div className="col-12 text-center mb-4">
                  <h3 className="font-weight-bold">Contact Us</h3>
                </div>

                {error && (
                  <div className="col-12 alert alert-danger" role="alert">
                    <p className="mb-0">
                      Something went wrong, please try again.
                      <button type="button" className="close" aria-label="Close" onClick={this.clearError}>
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </p>
                  </div>
                )}

                <div className="col-12 form-group">
                  <label className="control-label" htmlFor="helpInput">What can toptive do for you?</label>
                  <textarea
                    rows="4"
                    className="form-control"
                    id="helpInput"
                    name="help"
                    autoComplete="off"
                    maxLength="1000"
                    onChange={this.handleInputChange}
                    value={help}
                    readOnly={busy}
                  />
                </div>

                <div className={classNames("col-12 form-group", { error: !this.validation.isValidElement("name") })}>
                  <label className="control-label required" htmlFor="nameInput">Your name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="nameInput"
                    name="name"
                    autoComplete="off"
                    onChange={this.handleInputChange}
                    value={name}
                    required
                    ref={input => { this.validation.initElement(input, "name"); }}
                    readOnly={busy}
                  />
                  {!this.validation.isValidElement("name") && <span className="error-msg">This field is required</span>}
                </div>

                <div className={classNames("col-12 form-group", { error: !this.validation.isValidElement("email") })}>
                  <label className="control-label required" htmlFor="emailInput">Your email address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="emailInput"
                    name="email"
                    autoComplete="off"
                    onChange={this.handleInputChange}
                    value={email}
                    required
                    ref={input => { this.validation.initElement(input, "email"); }}
                    readOnly={busy}
                  />
                  {!this.validation.isValidElement("email", "valueMissing") && <span className="error-msg">This field is required</span>}
                  {!this.validation.isValidElement("email", "typeMismatch") && <span className="error-msg">Submit a valid email address</span>}
                </div>

                <div className={classNames("col-12 form-group", { error: !this.validation.isValidElement("phone") })}>
                  <label className="control-label" htmlFor="emailInput">Phone number</label>
                  <input
                    type="tel"
                    className="form-control"
                    id="phoneInput"
                    name="phone"
                    autoComplete="off"
                    onChange={this.handleInputChange}
                    value={phone}
                    required={false}
                    ref={input => { this.validation.initElement(input, "phone"); }}
                    readOnly={busy}
                  />
                  {!this.validation.isValidElement("phone") && <span className="error-msg">This field is required</span>}
                </div>

                <div className={classNames("col-12 form-group", { error: !this.validation.isValidElement("company") })}>
                  <label className="control-label" htmlFor="emailInput">What is your company or project name?</label>
                  <input
                    type="text"
                    className="form-control"
                    id="companyInput"
                    name="company"
                    autoComplete="off"
                    onChange={this.handleInputChange}
                    value={company}
                    required={false}
                    ref={input => { this.validation.initElement(input, "company"); }}
                    readOnly={busy}
                  />
                  {!this.validation.isValidElement("company") && <span className="error-msg">This field is required</span>}
                </div>

                <div className={classNames("col-12 form-group d-flex flex-column align-items-end", { error: !this.validation.isValidElement("file") })}>
                  <ReCAPTCHA
                    onChange={this.handleCaptchaCompleted}
                    sitekey="6LeCHa0UAAAAAKTIXiSIHs6x9QYA9lAd_iyxX-j5"
                  />
                  <input
                    type="text"
                    className="form-control"
                    id="keyInput"
                    name="key"
                    hidden
                    defaultValue={key}
                    tabIndex="-1"
                    required
                    ref={input => { this.validation.initElement(input, "key"); }}
                  />
                  {!this.validation.isValidElement("key") && <span className="error-msg">Please complete captcha</span>}
                </div>

                <div className="col-12 d-flex justify-content-between align-items-center mt-4">
                  <button type="submit" className="btn btn-capry uppercase mr-3 px-5">
                    {busy ? 'Sending' : 'Send'}
                    {busy && <i className="fas fa-spinner fa-spin ml-3" />}
                  </button>
                  <span style={{ fontSize: '1rem' }} className="text-right">
                    Or drop us an email to
                    {' '}
                    <a className="color-pink" href="mailto:info@toptive.co">info@toptive.co</a>
                  </span>
                </div>
              </form>

              {success && (
                <div className="row my-5">
                  <div className="col-12 text-center mb-4">
                    <h1 className="font-weight-bold uppercase color-capry mb-0">
                      Thank you for
                    </h1>
                    <h1 className="font-weight-bold uppercase">
                      Communicating with us
                    </h1>
                    <h2 className="pt-3 mt-4 text-center">We will be in contact as soon as possible</h2>

                    <div className="mt-5">
                      <Link to="/">
                        <button type="button" className="btn btn-capry">Go Back</button>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withFormValidation(ContactForm);
