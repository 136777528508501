import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import PageHeader from "../components/PageHeader/PageHeader";
import ContactForm from "../components/ContactForm/ContactForm";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";

class ContactPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title={`Contact | ${config.siteTitle}`} />
        <SEO />
        <Header />
        <PageHeader
          title="Let us help you to build an excellent software"
          content={(
            <span>
              Reach out to our specialists at
              {' '}
              <a href="mailto:info@toptive.co">info@toptive.co</a>
            </span>
          )}
        />
        <ContactForm />
        <Footer />
      </Layout>
    );
  }
}

export default ContactPage;
